<template>
  <div>
    <div class="container">
      <img class="bg" src="../assets/images/mobile/home-bg.jpg" />
      <div class="content">
        <div class="colume-item">
          <img class="bg" src="../assets/images/mobile/home-header-bg.png" />
          <div class="content header-item">
            <div class="logo-item">
              <img src="../assets/images/logo.png" />信鸽
            </div>
            <img class="title" src="../assets/images/mobile/home-title-01.png" />
            <img class="header-img" src="../assets/images/mobile/home-header-img.png" />
            <div class="btn" @click="handleDownloadApp"><img src="../assets/images/mobile/arrow-icon.png">前往交友</div>
          </div>
        </div>
        <div class="colume-item">
          <div class="beian">渝ICP备2023005590号-1</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Index',
  data () {
    return {
    }
  },
  mounted() {

  },
  methods: {
    handleDownloadApp() {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content {
    position: relative;
    z-index: 1;
  }
  .btn {
    width: 7.10rem;
    height: 1.12rem;
    border-radius: .56rem;
    background-color: #fde101;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: .36rem;
    font-weight: 600;
    margin-bottom: 1.10rem;
    img {
      width: .36rem;
      height: .36rem;
      margin-right: .10rem;
    }
  }
  .colume-item {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .title {
      width: 6.66rem;
    }
    .header-item {
      position: relative;
      width: 100%;
      min-height: 100vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      .logo-item {
        width: 6.40rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: .80rem;
        font-size: .6rem;
        color: #fff;
        font-style: italic;
        img {
          width: 1.00rem;
          margin-right: .2rem;
        }
      }
      .header-img {
        width: 6.62rem;
        margin-left: .30rem;
      }
    }
    .banner-item {
      margin: .72rem 0 .96rem;
      width: 7.00rem;
      height: 6.14rem;
      /deep/.carousel-3d-container {
        margin: 0;
        height: 307px !important;
        // height: auto !important;
        .carousel-3d-slide {
          background: none;
        }
      }
      .img-item {
        width: 233px;
        height: 307px;
        border-radius: .18rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .yinsi-img {
      width: 7.10rem;
      margin: .80rem 0 .98rem;
    }
    .tips {
      font-size: .24rem;
      color: rgba(255, 255, 255, .6);
      margin-bottom: .20rem;
    }
    .beian {
      font-size: .24rem;
      color: rgba(255, 255, 255, .4);
      margin: .10rem auto .20rem;
    }
  }
}
</style>
